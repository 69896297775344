import 'slick-carousel'
import momemt from 'moment';

jQuery( document ).ready(function($) {


  // MOBILE MENU
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu').toggleClass('active')
  })

  $('.menu a').click(function(){
    $('.menu, .menu-trigger').removeClass('active')
  })


  // POPUP
  $('.newsletter-trigger').click(function(e){
    e.preventDefault()
    $('.popup.newsletter').addClass('active')
  })
  $('.popup .close').click(function(){
    $(this).parent().parent().parent().removeClass('active')
  })


  // NEWSLETTER
  $( '.newsletter' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const DATA = $( this ).serialize();

		$.ajax( {
			type: 'POST',
			url: $( this ).attr( 'action' ),
			dataType: 'json',
			data: DATA,
			xhrFields: {
				withCredentials: false,
			},
			success: function ( data ) {
				$( '.inputs-wrap' ).html( '<p class="newsletter-thanks">Thanks for signing up!</p>' );
			},
			error: function ( err ) {
				console.log( err );
			}
		} );
	} );

  
  // SLIDERS
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-caret-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-caret-right"></button>',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          adaptiveHeight: false,
        }
      }
    ]
  });

  $('.slider-videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-caret-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-caret-right"></button>',
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: '240px',
        }
      }
    ]
  });


  // VIDEOS
  $('.video-trigger').click(function(){
    const theVideoId = $(this).attr('data-youtube-id')
    $(this).parent().find('iframe').attr('src', `https://www.youtube.com/embed/${theVideoId}`)
    $(this).parent().find('iframe').addClass('active')
  })


  // EVENTS
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Willie%20Jones/events?app_id=45PRESS_WILLIE_JONES',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + event.venue.region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-info">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        html += '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" class="btn btn-white has-bg" target="_blank"><img src="./dist/img/bg-btn-white.png" alt="" class="bg"><span class="content">' + offer.type + '</span></a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });


  // INSTAGRAM
  $.fn.juicer = function(options) {
    const $SELECTOR = $(this);
    let opts = $.extend({
        limit     : 8,
        feed_more : $('.juicer-button'),
        btn_msg   : 'View More'
    }, options);
    const init_limit = opts.limit;
    opts.feed_more.on('click', function (e) {
        if (opts.limit > 0) {
            opts.limit = 0;
            $(this).text('View Less');
        } else {
            opts.limit = init_limit;
            $(this).text(opts.btn_msg);
        }
        e.preventDefault();
    });
    $.ajax({
        url: 'https://www.juicer.io/api/feeds/williejones',
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            console.log(data);
            let c = 1;
            let html = '';
            for (let item of data.posts.items) {
                
                if (item.unformatted_message != null){
                    var text = item.unformatted_message.replace(/<\/?[^>]+(>|$)/g, '');
                } else{
                    var text = " ";
                }
                const img = item.image;
                if (img) {
                    
                    html += `
                            <div>
                                <a href="${item.full_url}" target="_blank" style="background-image: url('${img}');">
                                    <span>${text}</span>
                                </a>
                            </div>
                        `;
                } else {
                    html += `
                            <div>
                                <a href="${item.full_url}" target="_blank">
                                    <span style="opacity: 1;">${text}</span>
                                </a>
                            </div>
                        `;
                }
                if (opts.limit > 0 && c++ >= opts.limit) {
                    $SELECTOR.html(html);
                    return false;
                }
            }
            $SELECTOR.html(html);
            if (opts.limit === 0) {
                $('#feed > div').css('display', 'block');
            }
        },
        error: function () {
            console.log('Error loading feed.');
        }
    });
  };


  $('#feed').juicer();


});